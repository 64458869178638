import React from "react";
import PropTypes from "prop-types";

import Headline from "../Article/Headline";
import Bodytext from "../Article/Bodytext";
import Categories from "../../components/Category"

// const Page = props => {
class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      category: [],
      selectedcat: ''
    }
    this.SelectCategory = this.SelectCategory.bind(this);
    this.render_content = this.render_content.bind(this);
  }

  componentDidMount() {
    let category = [];
    if (this.props.page.frontmatter.title === 'About X2lab') {
      category.push(
        {
          'title': 'About Us'
        }, 
        {
          'title': 'Contact Us'
        }
      )
      this.setState({
        category: category,
        selectedcat: 'About Us'
      })
    } else {
      category.push({
        'title': this.props.page.frontmatter.title,
        'target': 'none'
      })
      this.setState({
        category: category,
        selectedcat: 'purple'
      })
    }
  }

  SelectCategory(key) {
  }

  render_content() {
    const {
      page: {
        html,
        frontmatter: { title }
      },
      theme
    } = this.props;
    return (
      <div className="about-category">
        <Categories categories = {this.state.category} selectedcat={this.state.selectedcat} onClick={this.SelectCategory} />
        <style jsx>{`
          .about-category {
            padding: 0rem;
            margin: 0 auto;
            margin-left: -5px;
            margin-right: -5px;
          }
          @from-width desktop {
          }
        `}</style>
      </div>
    )
  }

  SelectCategory() {

  }
  
  render() {
    console.log(this.props)
    const {
      page: {
        html,
        frontmatter: { title }
      },
      theme
    } = this.props;
    return (
      <React.Fragment>
        {this.render_content()}
        <div className="gatsbypage">
          <div className="content">
            <Headline title={title} theme={theme} />
            <Bodytext html={html} theme={theme} />
          </div>
          <style jsx>{` 
            .gatsbypage {
              padding: 10px;
              display: flex;
              justify-content: center;
              margin-bottom: 30px;
              max-width: 65em;
              margin: 0 auto !important;
            }
          `}</style>
        </div>
      </React.Fragment>
    );
  }
};

Page.propTypes = {
  page: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default Page;
